import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react";
import MaterialReactTable from "material-react-table";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { AccountContext } from "../Account";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Typography,
  Modal,
} from "@mui/material";
import {
  Delete,
  Edit,
  OpenInNew,
  Download,
  Settings,
  ConfirmationNumber,
  ConfirmationNumberTwoTone,
  SaveAltRounded,
  Save,
  Done,
} from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import ReactEcharts from "echarts-for-react";
import FullScreenSvg from "../assests/fullscreen.svg"
const SelectedIzci = () => {
  const navigate = useNavigate();

  const { apiKey, user_id } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [lastTime, setLastTime] = useState();
  const [tsdata, settsdata] = useState();
  const [columnObj, setColumnObj] = useState([]);
  const [filteredtsdata, setfilteredtsdata] = useState();
  const { id } = useParams();
  const [SancakPacketVersion, setSancakPacketVersion] = useState("");
  const [TPKPacketVersion, setTPKPacketVersion] = useState("");
  const [TPKMeasureCount, setTPKMeasureCount] = useState(0);
  const [TPKSleepTime, setTPKSleepTime] = useState(60);
  const [isConfigModal, setIsConfigModal] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [newdata, setnewdata] = useState(false);
  const [connection, setConnection] = useState(3);
  const [showGraph, setShowGraph] = useState(() => {
    if (window?.navigator?.userAgentData?.mobile) {
      return false;
    } else if (!window?.navigator?.userAgentData) {
      return false;
    } else {
      return true;
    }
  });

  useEffect(() => {
    console.log("id :::", id);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
  
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
  
    const fetchData = async () => {
      try {
        // İlk fetch işlemi
        const response1 = await fetch(
          `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
          requestOptions
        );
        const result1 = await response1.json();
        const resultfilter = result1.body;
  
        setTableData([resultfilter]);
  
        // İlk fetch işleminin sonucunu kullanarak ikinci fetch işlemi
        const response2 = await fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${resultfilter.id}&type=tabular&izcilength=${resultfilter.izci_length}`,
          { method: "GET" }
        );
        const result2 = await response2.json();
  
        // İkinci fetch işleminin sonucunu işle
        var obj = result2.body.map((e) => JSON.parse(e));
        console.log("objilk:", obj);
        settsdata(prev=> obj);
        setfull(true);

        var asd = JSON.stringify(result2.body);
          // console.log('new type data : ', result.body);
          var obj = [];
          result2.body.map((e) => {
            // console.log('aha bu e:', e);
            obj.push(JSON.parse(e));
          });
          setLastTime(obj[0].received_date);
          // console.log('obj:', obj);
          settsdata(obj);
          var colmndata = [];
          var keys = Object.keys(obj?.[0]);
          // console.log("keys66",keys);
          keys.map((e) => {
            // console.log("key",e)
            let tempcol = {};
            if ( e != "measure_name") {
              tempcol.accessorKey = e;
              tempcol.header = e;
              tempcol.size = 80;
              if (e == "event_date") {
                tempcol.accessorFn = (originalRow) => {
                  var mdate = new Date(Number(originalRow.event_date));
                  mdate.setMinutes(mdate.getMinutes());
  
                  originalRow.event_date = mdate;
                  return `${mdate.getHours()}:${
                    mdate.getMinutes() < 10
                      ? `0${mdate.getMinutes()}`
                      : mdate.getMinutes()
                  }  ${mdate.getDate()}.${
                    mdate.getMonth() + 1
                  }.${mdate.getFullYear()}`;
                };
              }
              if (e == "received_date") {
                tempcol.accessorFn = (originalRow) => {
                  var mdate = new Date(Number(originalRow.received_date));
                  mdate.setMinutes(mdate.getMinutes());
  
                  originalRow.received_date = mdate;
                  return `${mdate.getHours()}:${
                    mdate.getMinutes() < 10
                      ? `0${mdate.getMinutes()}`
                      : mdate.getMinutes()
                  }  ${mdate.getDate()}.${
                    mdate.getMonth() + 1
                  }.${mdate.getFullYear()}`;
                };
              }
  
              colmndata.push(tempcol);
            }
          });
          console.log("colmndata", colmndata);
          setColumnObj(colmndata);
      } catch (error) {
        console.log("error", error);
      }
    };
  
    // fetchData fonksiyonunu çağır
    if(connection == 3){
    fetchData();
  }
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(`wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${id}]`);

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener('open', (event) => {
      console.log('WebSocket bağlantısı başarıyla kuruldu.', event);
    });
    const pongInterval = setInterval(() => {
      socket.send('ping');
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener('message', (event) => {
      setnewdata(prev => true)
      console.log('WebSocketten gelen mesaj:', event);
      var dat = JSON.parse(event.data)
      var parseddata = {...dat.data, ...dat.data.izci_packet}
      console.log('dat dat dat:', parseddata);
      
      settsdata(prev => [parseddata ,...prev])
      
      console.log("tsdata",tsdata)
    });

    // Bağlantı kapandığında
    socket.addEventListener('close', (event) => {
      console.log('WebSocket bağlantısı kapatıldı.', event);
      clearInterval(pongInterval);
      
      setTimeout(() => {
        setConnection(prev => !prev)
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener('error', (event) => {
      console.error('WebSocket hatası:', event);
    });
    console.log("websocet kapalı triggered",socket.readyState); 
    

  }, [postdata,connection]);
  

  const FilterTimeFunction = (timeP)=>{
    var reqTime = new Date();
    var filteredArr;
    switch (timeP) {
      case "1m":
        // Tarihi bir ay öncesine göre 
    reqTime.setMonth(reqTime.getMonth() - 1);
    filteredArr = tsdata.filter(obje => Date.parse(obje.time) >= Date.parse(reqTime));
    setfilteredtsdata(filteredArr)
        break;
      case "6m":
        reqTime.setMonth(reqTime.getMonth() - 6);
        filteredArr = tsdata.filter(obje => Date.parse(obje.time) >= Date.parse(reqTime));
        setfilteredtsdata(filteredArr)
      break;
      case "1y":
        reqTime.setMonth(reqTime.getMonth() - 12);
        filteredArr = tsdata.filter(obje => Date.parse(obje.time) >= Date.parse(reqTime));
        setfilteredtsdata(filteredArr)
      break;
      case "all":
        
        setfilteredtsdata(tsdata)
      break;
    }
    
  }
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      console.log("deleted");
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      fetch(
        `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${row.getValue(
          "id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
  function setLast(time) {
    var mdate = new Date(Number(time));
    mdate.setMinutes(mdate.getMinutes());

    time = mdate;
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10
        ? `0${mdate.getMinutes()}`
        : mdate.getMinutes()
    }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableClickToCopy: true,
        size: 80,
      },
      {
        accessorKey: "izci_length",
        header: "length",
        enableEditing: true, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "status",
        header: "status",
        size: 80,
      },
      {
        accessorKey: "izci_hw_group",
        header: "hw_group",
        enableEditing: true, //disable editing on this column
        size: 80,
      },

      {
        accessorKey: "izci_hw_cfg",
        header: "hw_cfg",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_packet_ver",
        header: "packet_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_hw_ver",
        header: "hw_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_hw_rev",
        header: "hw_rev",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_sw_ver",
        header: "sw_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },

      {
        accessorKey: "produced_at",
        header: "produced_at",
        enableEditing: false, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "owner_id",
        header: "owner_id",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "qr",
        header: "qr",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            <QRCode
              id={cell.row.original.id}
              size={256}
              style={{
                height: "auto",
                width: "70px",
              }}
              value={cell.row.original.id}
              viewBox={`0 0 256 256`}
            />
          </>
        ),
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns120 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_battery_voltage",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_snr",
        header: "snr",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_rssi",
        header: "rssi",
        size: 80,
      },
      {
        accessorKey: "izci_sw_ver",
        header: "sw_ver",
        size: 80,
      },
      {
        accessorKey: "izci_lora_err",
        header: "loraErr",
        size: 80,
      },
      {
        accessorKey: "izci_sensors_err",
        header: "sensorsErr",
        size: 80,
        muiTableBodyCellProps: ({ cell }) => ({
          
          onClick: () => {
            var str = cell?.getValue().replaceAll("*","\n")
            alert(str);
          },
        }),
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorKey: "izci_tx_attempt",
        header: "attempt",
        size: 80,
      },
      {
        accessorKey: "izci_sm_05",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "izci_sm_15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "izci_sm_25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "izci_sm_35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "izci_sm_45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "izci_sm_55",
        header: "sm55",
        size: 80,
      },
      {
        accessorKey: "izci_sm_65",
        header: "sm65",
        size: 80,
      },
      {
        accessorKey: "izci_sm_75",
        header: "sm75",
        size: 80,
      },
      {
        accessorKey: "izci_sm_85",
        header: "sm85",
        size: 80,
      },
      {
        accessorKey: "izci_sm_95",
        header: "sm95",
        size: 80,
      },
      {
        accessorKey: "izci_sm_105",
        header: "sm105",
        size: 80,
      },
      {
        accessorKey: "izci_sm_115",
        header: "sm115",
        size: 80,
      },
      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_st_65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "izci_st_75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "izci_st_85",
        header: "st85",
        size: 80,
      },
      {
        accessorKey: "izci_st_95",
        header: "st95",
        size: 80,
      },
      {
        accessorKey: "izci_st_105",
        header: "st105",
        size: 80,
      },
      {
        accessorKey: "izci_st_115",
        header: "st115",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3);
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3);
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3);
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_65) / 1000000).toFixed(3);
        },
        header: "izci_sf_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_75) / 1000000).toFixed(3);
        },
        header: "izci_sf_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_85) / 1000000).toFixed(3);
        },
        header: "izci_sf_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_95) / 1000000).toFixed(3);
        },
        header: "izci_sf_95",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_105) / 1000000).toFixed(3);
        },
        header: "izci_sf_105",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_115) / 1000000).toFixed(3);
        },
        header: "izci_sf_115",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35);
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45);
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55);
        },
        header: "izci_sf_sd_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_65);
        },
        header: "izci_sf_sd_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_75);
        },
        header: "izci_sf_sd_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_85);
        },
        header: "izci_sf_sd_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_95);
        },
        header: "izci_sf_sd_95",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_105);
        },
        header: "izci_sf_sd_105",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_115);
        },
        header: "izci_sf_sd_115",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_95",
        header: "sc95",
        size: 80,
      },
      {
        accessorKey: "izci_sc_105",
        header: "sc105",
        size: 80,
      },
      {
        accessorKey: "izci_sc_115",
        header: "sc115",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_65",
        header: "scgb65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_75",
        header: "scgb75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_85",
        header: "scgb85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_95",
        header: "scgb95",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_105",
        header: "scgb105",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_115",
        header: "scgb115",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_95",
        header: "vbus95",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_105",
        header: "vbus105",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_115",
        header: "vbus115",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_65",
        header: "vbusgb65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_75",
        header: "vbusgb75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_85",
        header: "vbusgb85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_95",
        header: "vbusgb95",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_105",
        header: "vbusgb105",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_115",
        header: "vbusgb115",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns90 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_battery_voltage",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_snr",
        header: "snr",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_rssi",
        header: "rssi",
        size: 80,
      },
      {
        accessorKey: "izci_sw_ver",
        header: "sw_ver",
        size: 80,
      },
      {
        accessorKey: "izci_lora_err",
        header: "loraErr",
        size: 80,
      },
      {
        accessorKey: "izci_sensors_err",
        header: "sensorsErr",
        size: 80,
        muiTableBodyCellProps: ({ cell }) => ({
          
          onClick: () => {
            var str = cell?.getValue().replaceAll("*","\n")
            alert(str);
          },
        }),
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorKey: "izci_tx_attempt",
        header: "attempt",
        size: 80,
      },
      {
        accessorKey: "izci_sm_05",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "izci_sm_15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "izci_sm_25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "izci_sm_35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "izci_sm_45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "izci_sm_55",
        header: "sm55",
        size: 80,
      },
      {
        accessorKey: "izci_sm_65",
        header: "sm65",
        size: 80,
      },
      {
        accessorKey: "izci_sm_75",
        header: "sm75",
        size: 80,
      },
      {
        accessorKey: "izci_sm_85",
        header: "sm85",
        size: 80,
      },
      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_st_65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "izci_st_75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "izci_st_85",
        header: "st85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3);
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3);
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3);
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_65) / 1000000).toFixed(3);
        },
        header: "izci_sf_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_75) / 1000000).toFixed(3);
        },
        header: "izci_sf_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_85) / 1000000).toFixed(3);
        },
        header: "izci_sf_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35);
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45);
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55);
        },
        header: "izci_sf_sd_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_65);
        },
        header: "izci_sf_sd_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_75);
        },
        header: "izci_sf_sd_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_85);
        },
        header: "izci_sf_sd_85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_65",
        header: "scgb65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_75",
        header: "scgb75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_85",
        header: "scgb85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_65",
        header: "vbusgb65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_75",
        header: "vbusgb75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_85",
        header: "vbusgb85",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns60 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_battery_voltage",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_snr",
        header: "snr",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_rssi",
        header: "rssi",
        size: 80,
      },
      {
        accessorKey: "izci_sw_ver",
        header: "sw_ver",
        size: 80,
      },
      {
        accessorKey: "izci_lora_err",
        header: "loraErr",
        size: 80,
      },
      {
        accessorKey: "izci_sensors_err",
        header: "sensorsErr",
        size: 80,
        muiTableBodyCellProps: ({ cell }) => ({
          
          onClick: () => {
            var str = cell?.getValue().replaceAll("*","\n")
            alert(str);
          },
        }),
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorKey: "izci_tx_attempt",
        header: "attempt",
        size: 80,
      },
      {
        accessorKey: "izci_sm_05",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "izci_sm_15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "izci_sm_25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "izci_sm_35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "izci_sm_45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "izci_sm_55",
        header: "sm55",
        size: 80,
      },
      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3);
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3);
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3);
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35);
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45);
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55);
        },
        header: "izci_sf_sd_55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns30 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_battery_voltage",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_snr",
        header: "snr",
        size: 80,
      },
      {
        accessorKey: "ocak_lora_rssi",
        header: "rssi",
        size: 80,
      },
      {
        accessorKey: "izci_sw_ver",
        header: "sw_ver",
        size: 80,
      },
      {
        accessorKey: "izci_lora_err",
        header: "loraErr",
        size: 80,
      },
      {
        accessorKey: "izci_sensors_err",
        header: "sensorsErr",
        size: 80,
        muiTableBodyCellProps: ({ cell }) => ({
          
          onClick: () => {
            var str = cell?.getValue().replaceAll("*","\n")
            alert(str);
          },
        }),
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorKey: "izci_tx_attempt",
        header: "attempt",
        size: 80,
      },
      {
        accessorKey: "izci_sm_05",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "izci_sm_15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "izci_sm_25",
        header: "sm25",
        size: 80,
      },
      
      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
    const columnsall = useMemo(
        () => [...columnObj
          
        ],
        [columnObj,getCommonEditTextFieldProps]
      );
  const downloadQRCode = (row) => {
    console.log("row : ", row.original);
    var svg = document.getElementById(row.original.id);

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    //set url value to a element's href attribute.

    //you can download svg file by right click menu.
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;

    
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const [full, setfull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [time, settime] = useState([]);
  const [value, setvalue] = useState([]);
  const [max, setmax] = useState();
  const [min, setmin] = useState();

  function yaz(data2,attrname) {
    var time = [];
    var value = [];
    var lasttime;
    data2?.map((data) => {
      if (Date.parse(data.time) - lasttime < -7200000) {
        value.push([Date.parse(data.time) + 3200000, null]);
      }
      var mdate = new Date(data.time);
      mdate.setMinutes(mdate.getMinutes());
      value.push([mdate, Number(data[attrname]).toFixed(2)]);

      lasttime = Date.parse(data.time);
    });
    return value;
  }

  const DrawStackedTSSM = (data) => {
    console.log("gandalf",tableData)
    switch (tableData && tableData[0]?.izci_length ? tableData[0].izci_length : "") {
      case "60":
        var graphData1 = yaz(data,"izci_sm_05");
        var graphData2 = yaz(data,"izci_sm_15");
        var graphData3 = yaz(data,"izci_sm_25");
        var graphData4 = yaz(data,"izci_sm_35");
        var graphData5 = yaz(data,"izci_sm_45");
        var graphData6 = yaz(data,"izci_sm_55");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ["sm05", "sm15", "sm25", "sm35", "sm45", "sm55"]
        );
      case "90":
        var graphData1 = yaz(data,"izci_sm_05");
        var graphData2 = yaz(data,"izci_sm_15");
        var graphData3 = yaz(data,"izci_sm_25");
        var graphData4 = yaz(data,"izci_sm_35");
        var graphData5 = yaz(data,"izci_sm_45");
        var graphData6 = yaz(data,"izci_sm_55");
        var graphData7 = yaz(data,"izci_sm_65");
        var graphData8 = yaz(data,"izci_sm_75");
        var graphData9 = yaz(data,"izci_sm_85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "sm05",
            "sm15",
            "sm25",
            "sm35",
            "sm45",
            "sm55",
            "sm65",
            "sm75",
            "sm85",
          ]
        );
      default:
        var graphData1 = yaz(data,"izci_st_05");
        var graphData2 = yaz(data,"izci_st_15");
        var graphData3 = yaz(data,"izci_st_25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["sm05", "sm15", "sm25"]
        );
    }
  };
  const DrawStackedTSSMMemo = useMemo(
    () => DrawStackedTSSM( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  const DrawStackedTSST = (data) => {
    switch (tableData && tableData[0]?.izci_length ? tableData[0].izci_length : "") {
      case "60":
        var graphData1 = yaz(data,"izci_st_05");
        var graphData2 = yaz(data,"izci_st_15");
        var graphData3 = yaz(data,"izci_st_25");
        var graphData4 = yaz(data,"izci_st_35");
        var graphData5 = yaz(data,"izci_st_45");
        var graphData6 = yaz(data,"izci_st_55");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ["st05", "st15", "st25", "st35", "st45", "st55"]
        );
      case "90":
        var graphData1 = yaz(data,"izci_st_05");
        var graphData2 = yaz(data,"izci_st_15");
        var graphData3 = yaz(data,"izci_st_25");
        var graphData4 = yaz(data,"izci_st_35");
        var graphData5 = yaz(data,"izci_st_45");
        var graphData6 = yaz(data,"izci_st_55");
        var graphData7 = yaz(data,"izci_st_65");
        var graphData8 = yaz(data,"izci_st_75");
        var graphData9 = yaz(data,"izci_st_85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "st05",
            "st15",
            "st25",
            "st35",
            "st45",
            "st55",
            "st65",
            "st75",
            "st85",
          ]
        );
      default:
        var graphData1 = yaz(data,"st5");
        var graphData2 = yaz(data,"st15");
        var graphData3 = yaz(data,"st25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["st05", "st15", "st25"]
        );
    }
  };
  const DrawStackedTSSTMemo = useMemo(
    () => DrawStackedTSST( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  const DrawStackedTSAT = (data) => {
    var graphData1 = yaz(data,"izci_at");
    return StackedLine([graphData1], ["at"]);
  };
  const DrawStackedTSATMemo = useMemo(
    () => DrawStackedTSAT( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  const DrawStackedTSSF = (data) => {
    switch (tableData && tableData[0]?.izci_length ? tableData[0].izci_length : "") {
      case "60":
        var graphData1 = yaz(data,"izci_sf_05");
        var graphData2 = yaz(data,"izci_sf_15");
        var graphData3 = yaz(data,"izci_sf_25");
        var graphData4 = yaz(data,"izci_sf_35");
        var graphData5 = yaz(data,"izci_sf_45");
        var graphData6 = yaz(data,"izci_sf_55");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
          ],
          ["sf05", "sf15", "sf25", "sf35", "sf45", "sf55"]
        );
      case "90":
        var graphData1 = yaz(data,"izci_sf_05");
        var graphData2 = yaz(data,"izci_sf_15");
        var graphData3 = yaz(data,"izci_sf_25");
        var graphData4 = yaz(data,"izci_sf_35");
        var graphData5 = yaz(data,"izci_sf_45");
        var graphData6 = yaz(data,"izci_sf_55");
        var graphData7 = yaz(data,"izci_sf_65");
        var graphData8 = yaz(data,"izci_sf_75");
        var graphData9 = yaz(data,"izci_sf_85");
        return StackedLine(
          [
            graphData1,
            graphData2,
            graphData3,
            graphData4,
            graphData5,
            graphData6,
            graphData7,
            graphData8,
            graphData9,
          ],
          [
            "sf05",
            "sf15",
            "sf25",
            "sf35",
            "sf45",
            "sf55",
            "sf65",
            "sf75",
            "sf85",
          ]
        );
      default:
        var graphData1 = yaz(data,"izci_sf_05");
        var graphData2 = yaz(data,"izci_sf_15");
        var graphData3 = yaz(data,"izci_sf_25");
        return StackedLine(
          [graphData1, graphData2, graphData3],
          ["sf05", "sf15", "sf25"]
        );
    }
  };
  const DrawStackedTSSFMemo = useMemo(
    () => DrawStackedTSSF( filteredtsdata ? filteredtsdata : tsdata),
    [filteredtsdata,tsdata]
  );
  var csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: tableData[0]?.izci_length
      ? tableData[0].izci_length == 60
        ? columns60.map((c) => c.header)
        : tableData[0].izci_length == 90
        ? columns90.map((c) => c.header)
        : columns30.map((c) => c.header)
      : "",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(
      rows.map((row) => {
        var ordered = {};
        columns60.map((c) => (ordered[c.header] = row.original[c.header]));
        return ordered;
      })
    );
  };
  function handleOpenCalibration(row) {
    navigate(`calibration`);
  }
  const ChangeConfigurationFunction = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(TPKMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }
    var tempSleep = Number(TPKSleepTime).toString(16);
    if (tempSleep.length == 1) {
      tempSleep = `00${tempSleep}`;
    } else if (tempSleep.length == 2) {
      tempSleep = `0${tempSleep}`;
    }
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersion),
      i: id,
      c: `${TPKPacketVersion}${tempCont.toUpperCase()}${tempSleep.toUpperCase()}`,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${id}&type=izci`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setConfigLoading(false);
      })
      .then(() => setIsConfigModal(false))
      .catch((error) => console.log("error", error));
  };

  const rowVirtualizerInstanceRef = useRef(null);

  function toggleFullscreen(selectedclass) {
    let elem = document.querySelector(`.${selectedclass}`);
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
            alert(
              `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
            );
          })
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    }
    else if (elem.children[0].webkitEnterFullscreen) 
    { 
        elem.children[0].webkitEnterFullscreen();
        //toggle_controls(); //# your own function to show/hide iOS media controls
    } 
    else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    }

    // if (!document.fullscreenElement) {
      
    //   elem.requestFullscreen().catch((err) => {
    //     alert(
    //       `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
    //     );
    //   });
    // } else {
    //   document.exitFullscreen();
    // }
  }
  function isFiveMinutesAgo(dateString) {
    // Verilen tarihi JavaScript tarih nesnesine çevir
    const givenDate = new Date(dateString);
  
    // Şu anki tarihi al
    const now = new Date();
  
    // Verilen tarih ile şu anki tarih arasındaki farkı hesapla (milisaniye cinsinden)
    const timeDifference = now - givenDate;
  
    // Farkı dakikaya çevir
    const differenceInMinutes = timeDifference / (1000 * 60);
  
    // Eğer fark 5 dakikadan küçükse true, aksi takdirde false döndür
    return differenceInMinutes < 5;
  }
  useEffect(() => {
    if (newdata) {
      // Animasyon bitiminde setNewData(false) işlemini gerçekleştir
      const timeoutId = setTimeout(() => {
        setnewdata(prev => false);
      }, 1000); // Animasyon süresini burada belirleyebilirsiniz (örnekte 1 saniye)
      
      // Temizlik işlemleri
      return () => clearTimeout(timeoutId);
    }
  }, [newdata]);
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        enableRowNumbers
        enableRowActions
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableColumnActions={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        renderRowActionMenuItems={({ closeMenu, table, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              table.setEditingRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDeleteRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            Delete
          </MenuItem>,

          <MenuItem
            key={3}
            onClick={() => {
              downloadQRCode(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            Download QR Code
          </MenuItem>,
          <MenuItem
          key={3}
          onClick={() => {
            handleOpenCalibration(row);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          Calibration
        </MenuItem>
        ]}
      />
      <>
        <h1 className="text-4xl m-5 flex flex-row max-sm:flex-col gap-3 ">
          Measurements{" "}
          <p className="flex items-end text-xl text-blue-600">
            last measure: {tsdata && setLast(tsdata[0]?.received_date)}
          </p>
        </h1>

        <div className="mb-4">
          {full ? (
            <MaterialReactTable
              columns={
                columnsall
              }
              data={full && tsdata ? tsdata : []}
              editingMode="modal" //default
              enableRowNumbers
              enableFullScreenToggle={true}
              enableDensityToggle={false}
              enableGlobalFilter={false}
              enableHiding={false}
              enableColumnFilters={false}
              enablePagination={true}
              autoResetPageIndex={false}
              enableRowVirtualization
              rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
              rowVirtualizerProps={{ overscan: 8 }} //optionally customize the virtualizer
              enableStickyHeader
              muiTablePaginationProps={{
                rowsPerPageOptions: [10, 50, 200, 1000],
                showFirstButton: false,
                showLastButton: false,
              }}
              enableColumnActions={true}
              enableBottomToolbar={true}
              enableTopToolbar={true}
              enableRowSelection
              initialState={{
                density: "compact",
              }}
              state={{
                isLoading: !full,
              }}
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    disabled={
                      table.getPrePaginationRowModel().rows.length === 0
                    }
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export All Data
                  </Button>

                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() =>
                      handleExportRows(table.getSelectedRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export Selected Data
                  </Button>
                  <Button
                    disabled={
                      table.getPrePaginationRowModel().rows.length === 0
                    }
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() => setIsConfigModal(true)}
                    startIcon={<Settings />}
                    variant="contained"
                  >
                    Change Configuration
                  </Button>
                </Box>
              )}
              muiTableProp
              muiTableBodyCellProps={{
                sx: {
                  m: "auto",
                  width: "260px !important",
                  maxWidth: "1000px !important",
                  minWidth: "200px !important",
                },
              }}
              
              muiTableBodyRowProps={({
                row
              }) => ({
                sx: {
                  
                  animation: newdata & row.id < 1 ?"blink 1s ":"",
                  '@keyframes blink': {
                    '0%': { backgroundColor: 'white' },
                    '50%': { backgroundColor: '#fffd00' },
                    '100%': { backgroundColor: 'white' },
                  },
                 
                }
              })}
            />
          ) : (
            <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
            </div>
          )}
        </div>

        <Modal
          open={isConfigModal}
          onClose={() => setIsConfigModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 max-sm:w-full bg-white rounded-md  shadow-24 p-4  flex flex-col">
            <p className="text-2xl w-full  text-center pt- pb-4">Config</p>
            <div className="flex flex-col gap-2">
              <TextField
                id="outlined-basic"
                label="Ocak Id"
                value={tsdata?.[0]?.ocak_id}
                variant="standard"
              />
              <TextField
                id="outlined-basic"
                label="Izci Id"
                value={id}
                variant="standard"
              />
              <TextField
                id="outlined-basic"
                label="Sancak Packet Version"
                variant="standard"
                value={SancakPacketVersion}
                onChange={(event) => {
                  setSancakPacketVersion(event.target.value);
                }}
              />
              <p className="pt-3 font-bold">TPK Configs </p>
              <div className="flex flex-col pl-4 gap-2">
                <TextField
                  id="outlined-basic"
                  label="TPK Packet Version"
                  value={TPKPacketVersion}
                  onChange={(event) => {
                    setTPKPacketVersion(event.target.value);
                  }}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="TPK Measure Count (0 = infinity)"
                  value={TPKMeasureCount}
                  onChange={(event) => {
                    setTPKMeasureCount(event.target.value);
                  }}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="TPK Sleep Time (minute)"
                  variant="standard"
                  value={TPKSleepTime}
                  onChange={(event) => {
                    setTPKSleepTime(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className=" p-4 w-full flex flex-row gap-2 justify-end">
              <Button
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() => setIsConfigModal(false)}
                variant="text"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  SancakPacketVersion === "" ||
                  TPKPacketVersion === "" ||
                  TPKMeasureCount === "" ||
                  TPKSleepTime === ""
                }
                //export all rows, including from the next page, (still respects filtering and sorting)

                onClick={() => {
                  ChangeConfigurationFunction();
                  setConfigLoading(true);
                }}
                startIcon={configLoading ? " " : <Done />}
                variant="contained"
              >
                {configLoading ? (
                  <div className=" flex flex-row flex-wrap">
                    <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                  </div>
                ) : (
                  "Apply"
                )}
              </Button>
            </div>
          </Box>
        </Modal>
        {!full == true ? (
          <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
            <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
          </div>
        ) : (
          <div className={``}>
            <div className="flex flex-row mb-4 pl-5 gap-2">
            <Button variant="contained" onClick={() => setShowGraph(true)}>Show Graph</Button>
            <Button variant="contained" onClick={() => setShowGraph(false)}>Hide Graph</Button>
            </div>
            

            {showGraph == true ? (
              <div className=" max-sm:flex-col flex flex-row flex-wrap gap-5 ml-5 mr-5 max-sm:ml-0 max-sm:mr-0 mb-16">
                <div className="flex flex-row gap-1 w-full justify-center ">
            <Button variant="outlined" onClick={() => FilterTimeFunction("all")}>All </Button>
            <Button variant="outlined" onClick={() => FilterTimeFunction("1y")}>1 Year</Button>
            <Button variant="outlined" onClick={() => FilterTimeFunction("6m")}>6 Months</Button>
            <Button variant="outlined" onClick={() => FilterTimeFunction("1m")}>1 Month</Button>
            </div>
                <div className="max-w-full min-w-0 w-1/3  max-sm:w-full bg-white soilmois">
                  <p className="relative z-20 text-lg font-bold  p-3 flex flex-row justify-between">
                    Soil Moisture %{" "}
                    <Button
                      sx={{
                        fontSize: "1.5rem",
                        backgroundColor: "",
                        padding: "0.3rem",
                        lineHeight: "1rem",
                        minWidth: "1rem",
                      }}
                      onClick={() => toggleFullscreen("graphsm05")}
                    >
                      <img className="w-4 opacity-50" src={FullScreenSvg} />
                    </Button>
                  </p>

                  {DrawStackedTSSMMemo}
                </div>
                <div className="max-w-full min-w-0 w-1/3 max-sm:w-full  bg-white">
                  <p className="relative z-20 text-lg font-bold  p-3 flex flex-row justify-between">
                    Soil Temperature (°C){" "}
                    <Button
                      sx={{
                        fontSize: "1.5rem",
                        backgroundColor: "",
                        padding: "0.3rem",
                        lineHeight: "1rem",
                        minWidth: "1rem",
                      }}
                      onClick={() => toggleFullscreen("graphst05")}
                    >
                      <img className="w-4 opacity-50 " src={FullScreenSvg} />
                    </Button>
                  </p>
                  {DrawStackedTSSTMemo}
                </div>
                <div className="max-w-full min-w-0 w-1/4 max-sm:w-full  bg-white">
                  <p className="relative z-20 text-lg font-bold  p-3 flex flex-row justify-between">
                    Air Temperature (°C){" "}
                    <Button
                      sx={{
                        fontSize: "1.5rem",
                        backgroundColor: "",
                        padding: "0.3rem",
                        lineHeight: "1rem",
                        minWidth: "1rem",
                      }}
                      onClick={() => toggleFullscreen("graphat")}
                    >
                      <img className="w-4 opacity-50" src={FullScreenSvg} />
                    </Button>
                  </p>
                  {DrawStackedTSATMemo}
                </div>
                <div className="max-w-full min-w-0 w-1/3 max-sm:w-full  bg-white">
                  <p className="relative z-20 text-lg font-bold  p-3 flex flex-row justify-between">
                    EC (dS/m){" "}
                    <Button
                      sx={{
                        fontSize: "1.5rem",
                        backgroundColor: "",
                        padding: "0.3rem",
                        lineHeight: "1rem",
                        minWidth: "1rem",
                      }}
                      onClick={() => toggleFullscreen("graphsm05")}
                    >
                      <img className="w-4 opacity-50 " src={FullScreenSvg} />
                    </Button>
                  </p>
                  {DrawStackedTSSMMemo}
                </div>
                <div className="max-w-full min-w-0 w-7/12 max-sm:w-full  bg-white">
                  <p className="relative z-20 text-lg font-bold  p-3 flex flex-row justify-between">
                    Frequency (dS/m){" "}
                    <Button
                      sx={{
                        fontSize: "1.5rem",
                        backgroundColor: "",
                        padding: "0.3rem",
                        lineHeight: "1rem",
                        minWidth: "1rem",
                      }}
                      onClick={() => toggleFullscreen("graphsf05")}
                    >
                      <img className="w-4 opacity-50" src={FullScreenSvg} />
                    </Button>
                  </p>

                  {DrawStackedTSSFMemo}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </>
    </>
  );
};

const validateRequired = (value) => !!value.length;

export default SelectedIzci;

function StackedLine(rawdatas, names) {
  let option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      icon: "roundRect",
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        moveHandleSize: 16,
      },
      {
        type: "inside",
      },
    ],
    grid: [
      {
        left: "3%",
        right: "5%",
        bottom: "0%",
        top: "10%",
        height: "85%",
        containLabel: true,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      type: "time",
      axisPointer: {
        snap: false,
        lineStyle: {
          color: "#ff6600",
          width: 2,
        },
      },
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: true,
      },
      axisLine: { show: true },
      type: "value",
      max: function (value) {
        return value.max + 5;
      },
      min: function (value) {
        return value.min - 0;
      },
    },

    series: [],
    seriesCnt: "3",
    backgroundColor: "rgba(255,255,255,1)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: ["#2a10ac", "#00b7fb", "#df07dc"],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",

    symbol: "rect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      type: "line",
      symbol: "none",
      symbolSize: "5",
      data: e,
      lineStyle: {
        width: 4,
      },
    });
  });
  return (
    <ReactEcharts
      option={option}
      id={`graph${names[0]}`}
      className={`graph${names[0]} z-10`}
      lazyUpdate={true}
      opts={{ renderer: "svg" }}
    />
  );
}
