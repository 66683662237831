import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import MaterialReactTable from "material-react-table";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { AccountContext } from "../Account";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import { Delete, Edit, OpenInNew, Download } from "@mui/icons-material";
import ReactEcharts from "echarts-for-react";
const IzciCalibration = () => {
  const { apiKey, user_id } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [calibrationData, setCalibrationData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [lastTime, setLastTime] = useState();
  const [tsdata, settsdata] = useState();
  const [columnObj, setColumnObj] = useState([]);
  
  const [newdata, setnewdata] = useState(false);
  const [connection, setConnection] = useState(3);
  const { id } = useParams();

  const fetchDataAfterSetCalibre = async () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    try {
      // İlk fetch işlemi
      const response1 = await fetch(
        `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
        requestOptions
      );
      const result1 = await response1.json();
      const resultfilter = result1.body;

      setTableData([resultfilter]);
      let calibre = [
        { ...resultfilter.boru40Dolu, name: "boru40dolu" },
        { ...resultfilter.boru40Bos, name: "boru40Bos" },
        { ...resultfilter.boru32Dolu, name: "boru32dolu" },
        { ...resultfilter.boru32Bos, name: "boru32Bos" },
        { ...resultfilter.alkolDolu, name: "alkolDolu" },
        { ...resultfilter.alkolBos, name: "alkolBos" },
        { ...resultfilter.havaDolu, name: "havaDolu" },
        { ...resultfilter.havaBos, name: "havaBos" },
        { ...resultfilter.suDolu, name: "suDolu" },
        { ...resultfilter.suBos, name: "suBos" },
        { ...resultfilter.sebekeSuBos, name: "sebekeSuBos" },
      ];
      setCalibrationData(calibre);
      // İlk fetch işleminin sonucunu kullanarak ikinci fetch işlemi
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    console.log("id :::", id);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const fetchData = async () => {
      try {
        // İlk fetch işlemi
        const response1 = await fetch(
          `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
          requestOptions
        );
        const result1 = await response1.json();
        const resultfilter = result1.body;
        console.log("resultfilter", resultfilter);
        setTableData([resultfilter]);
        let calibre = [
          { ...resultfilter.boru40Dolu, name: "boru40dolu" },
          { ...resultfilter.boru40Bos, name: "boru40Bos" },
          { ...resultfilter.boru32Dolu, name: "boru32dolu" },
          { ...resultfilter.boru32Bos, name: "boru32Bos" },
          { ...resultfilter.alkolDolu, name: "alkolDolu" },
          { ...resultfilter.alkolBos, name: "alkolBos" },
          { ...resultfilter.havaDolu, name: "havaDolu" },
          { ...resultfilter.havaBos, name: "havaBos" },
          { ...resultfilter.suDolu, name: "suDolu" },
          { ...resultfilter.suBos, name: "suBos" },
          { ...resultfilter.sebekeSuBos, name: "sebekeSuBos" },
        ];
        setCalibrationData(calibre);
        // İlk fetch işleminin sonucunu kullanarak ikinci fetch işlemi
        const response2 = await fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${resultfilter.id}&type=tabular&izcilength=${resultfilter.izci_length}`,
          { method: "GET" }
        );
        const result2 = await response2.json();

        // İkinci fetch işleminin sonucunu işle
        var obj = result2.body.map((e) => JSON.parse(e));
        console.log("objilk:", obj);
        settsdata((prev) => obj);
        setfull(true);
        var asd = JSON.stringify(result2.body);
          // console.log('new type data : ', result.body);
          var obj = [];
          result2.body.map((e) => {
            // console.log('aha bu e:', e);
            obj.push(JSON.parse(e));
          });
          setLastTime(obj[0].received_date);
          // console.log('obj:', obj);
          settsdata(obj);
          var colmndata = [];
          var keys = Object.keys(obj?.[0]);
          // console.log("keys66",keys);
          keys.map((e) => {
            // console.log("key",e)
            let tempcol = {};
            if ( e != "measure_name") {
              tempcol.accessorKey = e;
              tempcol.header = e;
              tempcol.size = 80;
              if (e == "event_date") {
                tempcol.accessorFn = (originalRow) => {
                  var mdate = new Date(Number(originalRow.event_date));
                  mdate.setMinutes(mdate.getMinutes());
  
                  originalRow.event_date = mdate;
                  return `${mdate.getHours()}:${
                    mdate.getMinutes() < 10
                      ? `0${mdate.getMinutes()}`
                      : mdate.getMinutes()
                  }  ${mdate.getDate()}.${
                    mdate.getMonth() + 1
                  }.${mdate.getFullYear()}`;
                };
              }
              if (e == "received_date") {
                tempcol.accessorFn = (originalRow) => {
                  var mdate = new Date(Number(originalRow.received_date));
                  mdate.setMinutes(mdate.getMinutes());
  
                  originalRow.received_date = mdate;
                  return `${mdate.getHours()}:${
                    mdate.getMinutes() < 10
                      ? `0${mdate.getMinutes()}`
                      : mdate.getMinutes()
                  }  ${mdate.getDate()}.${
                    mdate.getMonth() + 1
                  }.${mdate.getFullYear()}`;
                };
              }
  
              colmndata.push(tempcol);
            }
          });
          console.log("colmndata", colmndata);
          setColumnObj(colmndata);
      } catch (error) {
        console.log("error", error);
      }
    };

    // fetchData fonksiyonunu çağır
    if (connection == 3) {
      fetchData();
    }
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(
      `wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${id}]`
    );

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener("open", (event) => {
      console.log("WebSocket bağlantısı başarıyla kuruldu.", event);
    });
    const pongInterval = setInterval(() => {
      socket.send("ping");
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener("message", (event) => {
      setnewdata((prev) => true);
      console.log("WebSocketten gelen mesaj:", event);
      var dat = JSON.parse(event.data);
      var parseddata = { ...dat.data, ...dat.data.izci_packet };
      console.log("dat dat dat:", parseddata);

      settsdata((prev) => [parseddata, ...prev]);

      console.log("tsdata", tsdata);
    });

    // Bağlantı kapandığında
    socket.addEventListener("close", (event) => {
      console.log("WebSocket bağlantısı kapatıldı.", event);
      clearInterval(pongInterval);

      setTimeout(() => {
        setConnection((prev) => !prev);
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener("error", (event) => {
      console.error("WebSocket hatası:", event);
    });
    console.log("websocet kapalı triggered", socket.readyState);
  }, [postdata, connection]);
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      console.log("deleted");
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      fetch(
        `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${row.getValue(
          "id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
  function setLast(time) {
    var mdate = new Date(Number(time));
    mdate.setMinutes(mdate.getMinutes());

    time = mdate;
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableClickToCopy: true,
        size: 80,
      },
      {
        accessorKey: "izci_length",
        header: "length",
        enableEditing: true, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "izci_hw_group",
        header: "hw_group",
        enableEditing: true, //disable editing on this column
        size: 80,
      },

      {
        accessorKey: "izci_hw_cfg",
        header: "hw_cfg",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_packet_ver",
        header: "packet_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_hw_ver",
        header: "hw_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_hw_rev",
        header: "hw_rev",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_sw_ver",
        header: "sw_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },

      {
        accessorKey: "produced_at",
        header: "produced_at",
        enableEditing: false, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "owner_id",
        header: "owner_id",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "qr",
        header: "qr",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            <QRCode
              id={cell.row.original.id}
              size={256}
              style={{
                height: "auto",
                width: "70px",
              }}
              value={cell.row.original.id}
              viewBox={`0 0 256 256`}
            />
          </>
        ),
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns120 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3);
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3);
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3);
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_65) / 1000000).toFixed(3);
        },
        header: "izci_sf_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_75) / 1000000).toFixed(3);
        },
        header: "izci_sf_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_85) / 1000000).toFixed(3);
        },
        header: "izci_sf_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_95) / 1000000).toFixed(3);
        },
        header: "izci_sf_95",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_105) / 1000000).toFixed(3);
        },
        header: "izci_sf_105",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_115) / 1000000).toFixed(3);
        },
        header: "izci_sf_115",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35);
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45);
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55);
        },
        header: "izci_sf_sd_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_65);
        },
        header: "izci_sf_sd_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_75);
        },
        header: "izci_sf_sd_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_85);
        },
        header: "izci_sf_sd_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_95);
        },
        header: "izci_sf_sd_95",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_105);
        },
        header: "izci_sf_sd_105",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_115);
        },
        header: "izci_sf_sd_115",
        size: 80,
      },
      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_st_65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "izci_st_75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "izci_st_85",
        header: "st85",
        size: 80,
      },
      {
        accessorKey: "izci_st_95",
        header: "st95",
        size: 80,
      },
      {
        accessorKey: "izci_st_105",
        header: "st105",
        size: 80,
      },
      {
        accessorKey: "izci_st_115",
        header: "st115",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_95",
        header: "sc95",
        size: 80,
      },
      {
        accessorKey: "izci_sc_105",
        header: "sc105",
        size: 80,
      },
      {
        accessorKey: "izci_sc_115",
        header: "sc115",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_65",
        header: "scgb65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_75",
        header: "scgb75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_85",
        header: "scgb85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_95",
        header: "scgb95",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_105",
        header: "scgb105",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_115",
        header: "scgb115",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_95",
        header: "vbus95",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_105",
        header: "vbus105",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_115",
        header: "vbus115",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_65",
        header: "vbusgb65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_75",
        header: "vbusgb75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_85",
        header: "vbusgb85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_95",
        header: "vbusgb95",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_105",
        header: "vbusgb105",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_115",
        header: "vbusgb115",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns90 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3);
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3);
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3);
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_65) / 1000000).toFixed(3);
        },
        header: "izci_sf_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_75) / 1000000).toFixed(3);
        },
        header: "izci_sf_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_85) / 1000000).toFixed(3);
        },
        header: "izci_sf_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35);
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45);
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55);
        },
        header: "izci_sf_sd_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_65);
        },
        header: "izci_sf_sd_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_75);
        },
        header: "izci_sf_sd_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_85);
        },
        header: "izci_sf_sd_85",
        size: 80,
      },

      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_st_65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "izci_st_75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "izci_st_85",
        header: "st85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_65",
        header: "scgb65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_75",
        header: "scgb75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_85",
        header: "scgb85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_65",
        header: "vbusgb65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_75",
        header: "vbusgb75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_85",
        header: "vbusgb85",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns60 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3);
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3);
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3);
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35);
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45);
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55);
        },
        header: "izci_sf_sd_55",
        size: 80,
      },

      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns30 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.event_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(Number(originalRow?.received_date));
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3);
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3);
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3);
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05);
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15);
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25);
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const calibaritionColumns120 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "name",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.event_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.received_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_05
            ? (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_15
            ? (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_25
            ? (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_35
            ? (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_45
            ? (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_55
            ? (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_65
            ? (Number(originalRow?.izci_sf_65) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_75
            ? (Number(originalRow?.izci_sf_75) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_85
            ? (Number(originalRow?.izci_sf_85) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_95
            ? (Number(originalRow?.izci_sf_95) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_95",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_105
            ? (Number(originalRow?.izci_sf_105) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_105",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_115
            ? (Number(originalRow?.izci_sf_115) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_115",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05)
            ? parseInt(originalRow?.izci_sf_sd_05)
            : null;
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15)
            ? parseInt(originalRow?.izci_sf_sd_15)
            : null;
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25)
            ? parseInt(originalRow?.izci_sf_sd_25)
            : null;
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35)
            ? parseInt(originalRow?.izci_sf_sd_35)
            : null;
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45)
            ? parseInt(originalRow.izci_sf_sd_45)
            : null;
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55)
            ? parseInt(originalRow?.izci_sf_sd_55)
            : null;
        },
        header: "izci_sf_sd_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_65)
            ? parseInt(originalRow?.izci_sf_sd_65)
            : null;
        },
        header: "izci_sf_sd_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_75)
            ? parseInt(originalRow?.izci_sf_sd_75)
            : null;
        },
        header: "izci_sf_sd_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_85)
            ? parseInt(originalRow?.izci_sf_sd_85)
            : null;
        },
        header: "izci_sf_sd_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_95)
            ? parseInt(originalRow?.izci_sf_sd_95)
            : null;
        },
        header: "izci_sf_sd_95",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_105)
            ? parseInt(originalRow?.izci_sf_sd_105)
            : null;
        },
        header: "izci_sf_sd_105",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_115)
            ? parseInt(originalRow?.izci_sf_sd_115)
            : null;
        },
        header: "izci_sf_sd_115",
        size: 80,
      },
      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_st_65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "izci_st_75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "izci_st_85",
        header: "st85",
        size: 80,
      },
      {
        accessorKey: "izci_st_95",
        header: "st95",
        size: 80,
      },
      {
        accessorKey: "izci_st_105",
        header: "st105",
        size: 80,
      },
      {
        accessorKey: "izci_st_115",
        header: "st115",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_95",
        header: "sc95",
        size: 80,
      },
      {
        accessorKey: "izci_sc_105",
        header: "sc105",
        size: 80,
      },
      {
        accessorKey: "izci_sc_115",
        header: "sc115",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_65",
        header: "scgb65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_75",
        header: "scgb75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_85",
        header: "scgb85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_95",
        header: "scgb95",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_105",
        header: "scgb105",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_115",
        header: "scgb115",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_95",
        header: "vbus95",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_105",
        header: "vbus105",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_115",
        header: "vbus115",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_65",
        header: "vbusgb65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_75",
        header: "vbusgb75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_85",
        header: "vbusgb85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_95",
        header: "vbusgb95",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_105",
        header: "vbusgb105",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_115",
        header: "vbusgb115",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const calibaritionColumns90 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "name",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.event_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.received_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_05
            ? (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_15
            ? (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_25
            ? (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_35
            ? (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_45
            ? (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_55
            ? (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_65
            ? (Number(originalRow?.izci_sf_65) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_75
            ? (Number(originalRow?.izci_sf_75) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_85
            ? (Number(originalRow?.izci_sf_85) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_85",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05)
            ? parseInt(originalRow?.izci_sf_sd_05)
            : null;
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15)
            ? parseInt(originalRow?.izci_sf_sd_15)
            : null;
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25)
            ? parseInt(originalRow?.izci_sf_sd_25)
            : null;
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35)
            ? parseInt(originalRow?.izci_sf_sd_35)
            : null;
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45)
            ? parseInt(originalRow.izci_sf_sd_45)
            : null;
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55)
            ? parseInt(originalRow?.izci_sf_sd_55)
            : null;
        },
        header: "izci_sf_sd_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_65)
            ? parseInt(originalRow?.izci_sf_sd_65)
            : null;
        },
        header: "izci_sf_sd_65",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_75)
            ? parseInt(originalRow?.izci_sf_sd_75)
            : null;
        },
        header: "izci_sf_sd_75",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_85)
            ? parseInt(originalRow?.izci_sf_sd_85)
            : null;
        },
        header: "izci_sf_sd_85",
        size: 80,
      },

      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_st_65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "izci_st_75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "izci_st_85",
        header: "st85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_65",
        header: "scgb65",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_75",
        header: "scgb75",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_85",
        header: "scgb85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_65",
        header: "vbusgb65",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_75",
        header: "vbusgb75",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_85",
        header: "vbusgb85",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const calibaritionColumns60 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "name",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.event_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.received_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_05
            ? (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_15
            ? (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_25
            ? (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_35
            ? (Number(originalRow?.izci_sf_35) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_45
            ? (Number(originalRow?.izci_sf_45) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_55
            ? (Number(originalRow?.izci_sf_55) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_55",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05)
            ? parseInt(originalRow?.izci_sf_sd_05)
            : null;
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15)
            ? parseInt(originalRow?.izci_sf_sd_15)
            : null;
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25)
            ? parseInt(originalRow?.izci_sf_sd_25)
            : null;
        },
        header: "izci_sf_sd_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_35)
            ? parseInt(originalRow?.izci_sf_sd_35)
            : null;
        },
        header: "izci_sf_sd_35",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow.izci_sf_sd_45)
            ? parseInt(originalRow.izci_sf_sd_45)
            : null;
        },
        header: "izci_sf_sd_45",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_55)
            ? parseInt(originalRow?.izci_sf_sd_55)
            : null;
        },
        header: "izci_sf_sd_55",
        size: 80,
      },

      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_st_35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "izci_st_45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "izci_st_55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_35",
        header: "scgb35",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_45",
        header: "scgb45",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_55",
        header: "scgb55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_35",
        header: "vbusgb35",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_45",
        header: "vbusgb45",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_55",
        header: "vbusgb55",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const calibaritionColumns30 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "name",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.event_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.event_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "event_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow?.received_date);
          mdate.setMinutes(mdate.getMinutes());

          originalRow.received_date = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
          `;
        },
        header: "received_date",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.ocak_id?.slice(-4);
        },
        header: "ocak_id",
        size: 80,
      },
      {
        accessorKey: "izci_seq",
        header: "seq",
        size: 80,
      },

      {
        accessorKey: "izci_at",
        header: "at",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_05
            ? (Number(originalRow?.izci_sf_05) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_15
            ? (Number(originalRow?.izci_sf_15) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return originalRow?.izci_sf_25
            ? (Number(originalRow?.izci_sf_25) / 1000000).toFixed(3)
            : null;
        },
        header: "izci_sf_25",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_05)
            ? parseInt(originalRow?.izci_sf_sd_05)
            : null;
        },
        header: "izci_sf_sd_05",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_15)
            ? parseInt(originalRow?.izci_sf_sd_15)
            : null;
        },
        header: "izci_sf_sd_15",
        size: 80,
      },

      {
        accessorFn: (originalRow) => {
          return parseInt(originalRow?.izci_sf_sd_25)
            ? parseInt(originalRow?.izci_sf_sd_25)
            : null;
        },
        header: "izci_sf_sd_25",
        size: 80,
      },

      {
        accessorKey: "izci_st_05",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "izci_st_15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "izci_st_25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_05",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_05",
        header: "scgb5",
        size: 80,
      },
      {
        accessorKey: "izci_sc_gb_15",
        header: "scgb15",
        size: 80,
      },

      {
        accessorKey: "izci_sc_gb_25",
        header: "scgb25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_05",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_05",
        header: "vbusgb5",
        size: 80,
      },
      {
        accessorKey: "izci_vbus_gb_15",
        header: "vbusgb15",
        size: 80,
      },

      {
        accessorKey: "izci_vbus_gb_25",
        header: "vbusgb25",
        size: 80,
      },
      {
        accessorKey: "izci_tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "izci_tc",
        header: "tc",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
   const columnsall = useMemo(
          () => [...columnObj
            
          ],
          [columnObj]
        );
  const downloadQRCode = (row) => {
    console.log("row : ", row.original.id);
    var svg = document.getElementById(row.original.id);

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    //set url value to a element's href attribute.

    //you can download svg file by right click menu.
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const [full, setfull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [time, settime] = useState([]);
  const [value, setvalue] = useState([]);
  const [max, setmax] = useState();
  const [min, setmin] = useState();

  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;
    tsdata?.map((data) => {
      if (Date.parse(data.time) - lasttime < -7200000) {
        value.push([Date.parse(data.time) + 3200000, null]);
      }
      var mdate = new Date(data.time);
      mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
      value.push([mdate, Number(data[attrname]).toFixed(2)]);

      lasttime = Date.parse(data.time);
    });
    return value;
  }
  const handleCalibration = async (values) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var id = values.id;

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(values),
    };
    console.log("calibre tableData[0]", tableData[0].id);
    console.log("values calibre", values);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/izci/${tableData[0].id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .then(() => {
        fetchDataAfterSetCalibre();
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (newdata) {
      // Animasyon bitiminde setNewData(false) işlemini gerçekleştir
      const timeoutId = setTimeout(() => {
        setnewdata((prev) => false);
      }, 1000); // Animasyon süresini burada belirleyebilirsiniz (örnekte 1 saniye)

      // Temizlik işlemleri
      return () => clearTimeout(timeoutId);
    }
  }, [newdata]);
  return (
    <>
      <h1 className="text-4xl font-bold">Calibration Page</h1>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        enableRowNumbers
        enableRowActions
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableColumnActions={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        renderRowActionMenuItems={({ closeMenu, table, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              table.setEditingRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDeleteRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            Delete
          </MenuItem>,

          <MenuItem
            key={3}
            onClick={() => {
              downloadQRCode(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            Download QR Code
          </MenuItem>,
        ]}
      />

      <div className="mb-12">
        {tableData[0] ? (
          <MaterialReactTable
            columns={
              tableData[0].izci_length && tableData[0].izci_length == 60
                ? calibaritionColumns60
                : tableData[0].izci_length && tableData[0].izci_length == 90
                ? calibaritionColumns90
                : tableData[0].izci_length && tableData[0].izci_length == 120
                ? calibaritionColumns120
                : tableData[0].izci_length && tableData[0].izci_length == 30
                ? calibaritionColumns30
                : []
            }
            data={calibrationData}
            editingMode="modal" //default
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableGlobalFilter={false}
            enableHiding={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableColumnActions={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
          />
        ) : (
          ""
        )}
      </div>

      <>
        <h1 className="text-4xl m-5 flex flex-row gap-3 ">
          Measurements{" "}
          <p className="flex items-end text-xl text-blue-600">
            last measure: {tsdata && setLast(tsdata[0]?.received_date)}
          </p>
        </h1>
        {tsdata &&
          (full == true ? (
            <div className="mb-12">
              <MaterialReactTable
                columns={
                  columnsall
                }
                data={tsdata}
                editingMode="modal" //default
                enableRowNumbers
                enableRowActions
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableGlobalFilter={false}
                enableHiding={false}
                enableColumnFilters={false}
                enablePagination={true}
                enableColumnActions={false}
                enableBottomToolbar={true}
                enableTopToolbar={false}
                renderRowActionMenuItems={({ closeMenu, table, row }) => [
                  <MenuItem
                    key={0}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ boru40Dolu: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Boru 40 Dolu
                  </MenuItem>,
                  <MenuItem
                    key={1}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ boru40Bos: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Boru 40 Boş
                  </MenuItem>,
                  <MenuItem
                    key={2}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ boru32Dolu: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Boru 32 Dolu
                  </MenuItem>,
                  <MenuItem
                    key={3}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ boru32Bos: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Boru 32 Boş
                  </MenuItem>,
                  <MenuItem
                    key={4}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ alkolDolu: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Alkol Dolu
                  </MenuItem>,
                  <MenuItem
                    key={5}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ alkolBos: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Alkol Boş
                  </MenuItem>,
                  <MenuItem
                    key={6}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ havaDolu: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Hava Dolu
                  </MenuItem>,
                  <MenuItem
                    key={7}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ havaBos: row.original });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Hava Boş
                  </MenuItem>,
                  <MenuItem
                    key={8}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ suDolu: row.original });

                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Su Dolu
                  </MenuItem>,
                  <MenuItem
                    key={9}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ suBos: row.original });

                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Su Boş
                  </MenuItem>,
                  <MenuItem
                    key={10}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({ sebekeSuBos: row.original });

                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Şebeke Su Boş
                  </MenuItem>,
                  <MenuItem
                    key={11}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({
                        suBos: "",
                        suDolu: "",
                        havaBos: "",
                        havaDolu: "",
                        boru32Bos: "",
                        boru32Dolu: "",
                        boru40Bos: "",
                        boru40Dolu: "",
                        alkolBos: "",
                        alkolDolu: "",
                      });
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Clear All Calibration
                  </MenuItem>,
                ]}
                muiTableBodyRowProps={({ row }) => ({
                  sx: {
                    animation: newdata & (row.id < 1) ? "blink 1s " : "",
                    "@keyframes blink": {
                      "0%": { backgroundColor: "white" },
                      "50%": { backgroundColor: "#fffd00" },
                      "100%": { backgroundColor: "white" },
                    },
                  },
                })}
              />
            </div>
          ) : (
            <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
            </div>
          ))}
      </>
    </>
  );
};

const validateRequired = (value) => !!value.length;

export default IzciCalibration;
